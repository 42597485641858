// useRem.ts
import { useEffect } from "react";

const useRem = (designWidth: number = 375, baseSize: number = 16): void => {
  const setRemUnit = (): void => {
    const html = document.documentElement;
    const screenWidth = html?.clientWidth || window?.innerWidth;
    const scale = screenWidth / designWidth;
    html.style.fontSize = `${baseSize * scale}px`;
  };

  useEffect(() => {
    setRemUnit();
    window.addEventListener("resize", setRemUnit);
    return () => {
      window.removeEventListener("resize", setRemUnit);
    };
  }, [designWidth, baseSize]);
};

export default useRem;
