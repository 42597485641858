import { useLaunchParams } from '@telegram-apps/sdk-react'
import { useEffect } from 'react'
import { receiveGift } from '@/api/record'

export const UseInviteLink = () => {
  const lp = useLaunchParams()
  useEffect(() => {
    const timer = setTimeout(() => {
      const params = lp.startParam
      if (params?.includes('gift')) {
        const giftId = params.split('gift')[1]
        receiveGift({
          sender: giftId,
        })
      }
      console.log(params, '4396->>>>>>>>>>>>>>>>>>>>>>>')
      clearTimeout(timer)
    }, 3000)
  }, [lp.startParam])
}
