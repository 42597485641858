import request from './config'

export type RecordType = {
  desc: string
  score: number
  type: number
  time?: string
  id: number
  created_at: string
}

type ParamsType = {
  page?: number
  size?: number
  type: number
}
export const getWalletRecord = async (params: ParamsType) => {
  const { page = 1, size = 20, type = 0 } = params
  const res = await request.get(
    `/api/wallet?page=${page}&size=${size}&type=${type}`
  )
  if (res.code === 0) {
    return res.data.data.map((item: RecordType) => {
      item.time = item.created_at?.split(' ')[0]
      return item
    })
  }
  return []
}

export const getMutualAidsInfo = async () => {
  const res = await request.get('/api/activities/mutual-aid')
  if (res.code === 0) {
    return res.data
  }
  return []
}

export const receiveGift = async (params: { sender: string | number }) => {
  const res = await request.post('/api/activities/mutual-aid', params)
  if (res.code === 0) {
    return true
  }
  return false
}
