import md5 from "js-md5";
import { initUtils } from "@telegram-apps/sdk";
const utils = initUtils();

export const openTelegramLink = async (url: string) => {
  return utils.openTelegramLink(url);
};

export const openLink = async (url: string) => {
  return utils.openLink(url);
};
export function generateRandomString(length = 6) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function getInviteLink(
  userId: string | number,
  type = 1,
  repalyContent = ""
) {
  // todo
  const replay1 = `🎉Join PalmCode and discover your fate! 🎉
Scan your palm✋, climb the ranks🏆, and earn $TON rewards💰.`;
  const replay2 = "👇👇👇Join To Game👇👇👇";
  const replay =
    encodeURI(repalyContent) || encodeURI(`${replay1} \n ${replay2}`);
  const isDev =
    import.meta.env.VITE_MODEL === "development" ||
    import.meta.env.VITE_MODEL === "preview";
  let url;
  if (type === 1) {
    // invite friend
    url = `https://t.me/share/url?text=${replay}&url=t.me/twa_stage_bot/palm?startapp=friend${userId}`;
    if (isDev) {
      url = `https://t.me/share/url?text=${replay}&url=t.me/twa_stage_bot/palm?startapp=friend${userId}`;
    }
  } else if (type === 2) {
    // gift box
    url = `https://t.me/share/url?text=${replay}&url=t.me/twa_stage_bot/palm?startapp=gift${userId}`;
    if (isDev) {
      url = `https://t.me/share/url?text=${replay}&url=t.me/twa_stage_bot/palm?startapp=gift${userId}`;
    }
  } else {
    // default
    url = `https://t.me/share/url?text=${replay}&url=t.me/twa_stage_bot/palm?startapp=gift${userId}`;
    if (isDev) {
      url = `https://t.me/share/url?text=${replay}&url=t.me/twa_stage_bot/palm?startapp=gift${userId}`;
    }
  }
  openTelegramLink(url);
}
export const DEFAULT_AVATAR = "/imgs/earn.jpg";
export const getAvatar = (avatar: string | number) => {
  try {
    const avatarMd = md5(avatar + "");
    const url = `https://image.googlescdn.com/${avatarMd}`;
    return url;
  } catch (error) {
    console.log(error, "error");
    return DEFAULT_AVATAR;
  }
};
export function loadImg(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve();
    img.onerror = (err) => reject(err);
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stringifyInitData(parsedData: Record<string, any>) {
  const params = new URLSearchParams();
  params.append(
    "user",
    encodeURIComponent(
      JSON.stringify({
        id: parsedData.id,
        first_name: parsedData.firstName,
        last_name: parsedData.lastName,
        username: parsedData.username,
        language_code: parsedData.languageCode,
        is_premium: parsedData.isPremium,
      })
    )
  );
  const str = params.toString().replaceAll("25", "");
  return str;
}

export function fileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

export async function saveFileToLocalStorage(file: File) {
  const base64 = (await fileToBase64(file)) as string;
  localStorage.setItem("myFile", base64);
}

export async function getFileFromLocalStorage() {
  const base64 = localStorage.getItem("myFile");
  if (base64) {
    const response = await fetch(base64);
    const blob = await response.blob();
    return new File([blob], "image.jpg", { type: "image/jpeg" });
  }
  return null;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTaskStatus = (item: any) => {
  //['Go to', 'Receive', 'Finish'] 0 1 2
  if (!item?.target?.is_completion_target) {
    return 1;
  }
  if (
    item?.target?.is_completion_target &&
    !item?.target?.is_reward_collection
  ) {
    return 2;
  }
  if (
    item?.target?.is_completion_target &&
    item?.target?.is_reward_collection
  ) {
    return 3;
  }
};
