import axios, { AxiosRequestConfig } from 'axios'
import { initInitData } from '@telegram-apps/sdk'
import { stringifyInitData } from '@/utils'
import { getStorage } from '@/utils/storage'
const $config = import.meta.env
const requestUrl = $config.VITE_BASE_URL
let source = axios.CancelToken.source()
const instance = axios.create({
  baseURL: requestUrl,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})
const init = initInitData()
const initData = stringifyInitData(init!.user || {})
const whiteUrlList = ['login']
instance.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function (config: any) {
    const token = getStorage('token')
    let flag = false
    try {
      flag = whiteUrlList.some((item) => config?.url.endsWith(item))
    } catch (error) {
      console.log(error)
    }
    if (token && !flag) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    if (typeof config.data == 'object') {
      config.data.time = parseInt(String(new Date().getTime() / 1000))
    }
    console.log(initData, 'test->>.')
    const lang = localStorage.getItem('i18nextLng_code')
    config.headers['Tg-Token'] = `${initData}`
    config.headers['lan'] = lang || 'en'
    config.cancelToken = source.token
    return config
  },
  function (err) {
    return Promise.reject(err)
  }
)
instance.interceptors.response.use(
  (res) => res,
  function (err) {
    console.log('err=====', err)
    return Promise.reject(err)
  }
)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const request: any = {}
request.cancel = (msg = '') => {
  source.cancel(msg)
  source = axios.CancelToken.source()
}
request.post = (
  url: string,
  params: Record<string, unknown>,
  config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params, config)
      .then(async (res) => {
        const ret: AxiosRequestConfig<unknown> = res
        if (res.data.code == 403) {
          location.reload()
        }
        return resolve(ret.data)
      })
      .catch((err) => {
        return reject(err)
      })
  })
}
request.get = (url: string, params: Record<string, unknown>) => {
  return new Promise((resolve, reject) => {
    let query = ''
    if (params) {
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          query += `${encodeURIComponent(key)}=${encodeURIComponent(
            params[key] as string
          )}&`
        }
      }
      query = query.slice(0, -1)
    }
    const resultUri = query ? `${url}?${query}` : url
    instance
      .get(resultUri)
      .then(async (res) => {
        if (res.data.code == 403) {
          location.reload()
        }
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

request.put = (
  url: string,
  params: Record<string, unknown>,
  config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  return new Promise((resolve, reject) => {
    instance
      .put(url, params, config)
      .then(async (res) => {
        const ret: AxiosRequestConfig<unknown> = res
        if (res.data.code == 403) {
          location.reload()
        }
        return resolve(ret.data)
      })
      .catch((err) => {
        return reject(err)
      })
  })
}
export default request
