import { useEffect, useState, type FC } from "react";
import { AppRoot } from "@telegram-apps/telegram-ui";
import UseInitTgInit from "@/hooks/useTginit";
import { useUserDataStore } from "@/store/user";
import { TabBar as AntdTabbar, SafeArea } from "antd-mobile";
import { useBaseDataStore } from "../store/baseData";
import Vconsole from "vconsole";
import LoadPage from "@/components/LoadPage/LoadPage";
import "@aarsteinmedia/dotlottie-player";
import { userLogin, getUserInfo } from "@/api/user";
import { UseInviteLink } from "@/hooks/useInvite";
import {
  useNavigate,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import AppRoutes, { routes, tabsRoute } from "./AppRoute";
import {
  useLaunchParams,
  useMiniApp,
  SDKProvider,
} from "@telegram-apps/sdk-react";
import useRem from "@/hooks/useRem";
import { imagesArray, initArray } from "../resource/index";
import "@telegram-apps/telegram-ui/dist/styles.css";
import { loadImg } from "@/utils";
import i18n from "@locales/i18next";
import { I18nextProvider } from "react-i18next";
// import { TonConnectUIProvider } from '@tonconnect/ui-react';
import "./App.css";
import styles from "./App.module.css";
import "./tabbar.scss";
import { getStorage, setStorage } from "@/utils/storage";
const Bottom: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const setRouteActive = (value: string) => {
    navigate(value);
  };

  return (
    <AntdTabbar
      activeKey={pathname}
      onChange={(value) => setRouteActive(value)}
    >
      {tabsRoute.map((item) => (
        <AntdTabbar.Item
          key={item.path}
          icon={item.icon}
          title={item.tabbarTitle}
        />
      ))}
    </AntdTabbar>
  );
};

const App: FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(true);
  UseInitTgInit();
  UseInviteLink();
  const miniApp = useMiniApp();
  const lp = useLaunchParams();
  const updateUserinfoKey = useUserDataStore(
    (state) => state.updateUserinfoKey
  );
  const updateBaseinfo = useUserDataStore((state) => state.updateBaseinfo);
  const updatePalmImg = useBaseDataStore((state) => state.updatePalmImg);
  const updateUserCurrentScore = useBaseDataStore(
    (state) => state.updateUserCurrentScore
  );
  const updateImgobj = useBaseDataStore((state) => state.updateImgobj);
  async function init() {
    const imgObj: Record<string, string> = {};
    const itemAdd = 100 / initArray.length - 1;
    await Promise.all(
      initArray.map(async (item) => {
        const { path, name } = item;
        try {
          await loadImg(path);
          imgObj[name] = path;
          setCurrentStep((prev) => prev + itemAdd);
        } catch (error) {
          console.error(`Failed to load image ${name}:`, error);
        }
      })
    );
    for (const item of imagesArray) {
      const { path, name } = item;
      try {
        loadImg(path);
        imgObj[name] = path;
      } catch (error) {
        console.error(`Failed to load image ${name}:`, error);
      }
    }
    updateImgobj(imgObj);
    try {
      let invite_id = "";
      const params = lp.startParam;
      if (params?.includes("friend")) {
        invite_id = params.split("friend")[1];
      }
      const res = await userLogin(invite_id);
      getUserInfo()
        .then((res) => {
          if (res.code === 0) {
            const data = res?.data;
            const gold = +data?.gold;
            updateBaseinfo(data);
            updateUserinfoKey({
              point: gold,
            });
            console.log(res, 7777);
            console.log(data?.extra, 111);

            try {
              if (data?.extra?.palmistry_pic) {
                console.log(
                  data?.extra?.palmistry_pic,
                  "data?.extra?.palmistry_pic"
                );
                updatePalmImg(data?.extra?.palmistry_pic);
                const key = "palm_img" + data.tgid;
                const img = getStorage(key);
                if (img) {
                  loadImg(img as string);
                }
                updateUserCurrentScore(data?.extra?.reward || 0);
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            console.log(res, "res");
          }
          setCurrentStep(100);
          setLoading(false);
        })
        .catch(() => {
          setCurrentStep(100);
          setLoading(false);
        });
      setStorage("token", res?.auth_token);
    } catch (error) {
      console.log(error);
    }
  }

  useRem(375, 16);
  useEffect(() => {
    miniApp.setHeaderColor("#000000");
    new Vconsole();
    init();
    document.body.dir = i18n.dir();
  }, []);
  return (
    // <TonConnectUIProvider manifestUrl={manifestUrl}>
    <I18nextProvider i18n={i18n}>
      {!loading ? (
        <AppRoot
          appearance={miniApp.isDark ? "dark" : "light"}
          platform={["macos", "ios"].includes(lp.platform) ? "ios" : "base"}
        >
          <Router>
            <AppContent />
          </Router>
        </AppRoot>
      ) : (
        <LoadPage currentStep={currentStep}></LoadPage>
      )}
    </I18nextProvider>
    // </TonConnectUIProvider>
  );
};
const AppContent: React.FC = () => {
  const location = useLocation();
  const currentRoute = routes.find((route) => route.path === location.pathname);
  const isTabsRoute =
    currentRoute &&
    (tabsRoute.some((tab) => tab.path === currentRoute.path) ||
      currentRoute.path === "/");
  return (
    <div className={styles.app}>
      <div className={styles.appBody}>
        <AppRoutes />
      </div>
      {isTabsRoute && (
        <div className={styles.bottom}>
          <Bottom />
        </div>
      )}
      <SafeArea position="bottom"></SafeArea>
    </div>
  );
};

const Inner: FC = () => {
  let debug = false;
  try {
    const lp = useLaunchParams();
    debug = lp?.startParam === "debug";
  } catch (error) {
    console.log(error, 1);
  }
  return (
    <>
      <SDKProvider acceptCustomStyles debug={debug}>
        <App />
      </SDKProvider>
    </>
  );
};

const Root: FC = () => <Inner />;

export default Root;
