import { type JSX, lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
const Earn = lazy(() => import("@/pages/Earn/Earn"));
const Banlance = lazy(() => import("@/pages/Balance/Balance"));
const Invite = lazy(() => import("@/pages/Invite/Invite"));
const Reward = lazy(() => import("@/pages/Reward/Reward"));
const Ranking = lazy(() => import("@/pages/Ranking/Ranking"));
const BeginGuide = lazy(() => import("@/pages/BeginGuide/BeginGuide"));
const TakePhoto = lazy(() => import("@/pages/TakePhoto/TakePhoto"));
const PalmResult = lazy(() => import("@/pages/PalmResult/PalmResult"));
const SystemMaintenance = lazy(
  () => import("@/pages/SystemManitenance/SystemManitenance")
);
import { ToggleImg } from "@/components/ToggleImg/ToggleImg";

interface Route {
  path: string;
  element: JSX.Element;
  title?: string;
  tabbarTitle?: string;
  icon?: JSX.Element | string | ((active: boolean) => JSX.Element);
  isnotShowNavbar?: boolean;
}
const routes: Route[] = [
  {
    path: "/earn",
    element: <Earn />,
    title: "My Earn",
    tabbarTitle: "Earn",
    icon: (active: boolean) => (
      <ToggleImg
        active={active}
        src={"/imgs/btn_earn.png"}
        scr_off={"/imgs/btn_earn1.png"}
        classname="w-[28px] h-[32px]"
      ></ToggleImg>
    ),
  },
  {
    path: "/reward",
    element: <Reward />,
    title: "My Reward",
    tabbarTitle: "Reward",
    icon: (active: boolean) => (
      <ToggleImg
        active={active}
        src={"/imgs/btn_reward.png"}
        scr_off={"/imgs/btn_reward1.png"}
        classname="w-[30px] h-[23px]"
      ></ToggleImg>
    ),
  },
  {
    path: "/ranking",
    element: <Ranking />,
    title: "Ranking",
    tabbarTitle: "Top",
    icon: (active: boolean) => (
      <ToggleImg
        active={active}
        src={"/imgs/btn_top.png"}
        scr_off={"/imgs/btn_top1.png"}
        classname="w-[27px] h-[30px]"
      ></ToggleImg>
    ),
  },
  {
    path: "/invite",
    element: <Invite />,
    title: "Invite",
    tabbarTitle: "Invite",
    icon: (active: boolean) => (
      <ToggleImg
        active={active}
        src={"/imgs/btn_invite.png"}
        scr_off={"/imgs/btn_invite1.png"}
        classname="w-[30px] h-[24px]"
      ></ToggleImg>
    ),
  },
  {
    path: "/banlance",
    element: <Banlance />,
    title: "Banlance",
    tabbarTitle: "Banlance",
    icon: (active: boolean) => (
      <ToggleImg
        active={active}
        src={"/imgs/btn_balance.png"}
        scr_off={"/imgs/btn_balance1.png"}
        classname="w-[15px] h-[30px]"
      ></ToggleImg>
    ),
  },
  // { path: '/', element: <IndexPage />, isnotShowNavbar: true },
  { path: "/begin-guide", element: <BeginGuide />, isnotShowNavbar: true },
  { path: "/take-photo", element: <TakePhoto />, isnotShowNavbar: true },
  { path: "/palm-result", element: <PalmResult />, isnotShowNavbar: true },
  {
    path: "/system-maintenance",
    element: <SystemMaintenance />,
    isnotShowNavbar: true,
  },
];

const tabsRoute = routes.slice(0, 5);

const AppRoutes = () => (
  <Suspense fallback={<div />}>
    <Routes>
      {routes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
      <Route path="*" element={<Navigate to="/ranking" />} />
    </Routes>
  </Suspense>
);

export { routes, tabsRoute };
export default AppRoutes;
