import "./LoadPage.scss";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { DotLoading } from "antd-mobile";
type PropsType = {
  currentStep?: number;
};
export default function LoadPage(props: PropsType) {
  const { currentStep = 0 } = props;
  const totalBlocks = 25;
  const filledBlocks = Math.round((currentStep / 100) * totalBlocks);
  return (
    <div className="flex flex-col w-screen h-screen justify-around py-[100px]">
      <img src="/imgs/PalmCode.png" className="w-[226px] h-[36px]" alt="" />
      <DotLottieReact
        className="w-[178px] h-[178px]"
        src="/loading_palm.json"
        autoplay={true}
        // controls={false}
        loop={true}
      />
      <div className="process-wrapper flex flex-col">
        <div className="flex text-thc text-[24px]">
          <div className="font-bold">Loading</div>
          <DotLoading color="currentColor" />
        </div>
        {/* bar */}
        <div className="progress-container">
          {Array.from({ length: totalBlocks }, (_, index) => (
            <div
              key={index}
              className={`progress-block ${
                index < filledBlocks ? "filled" : ""
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
