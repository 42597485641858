export const imagesArray: Array<Record<string, string>> = [
  { name: "Detection_1", path: "/imgs/Detection_1.png" },
  { name: "Detection_2", path: "/imgs/Detection_2.png" },
  { name: "Detection_3", path: "/imgs/Detection_3.png" },
  { name: "balance_1", path: "/imgs/balance_1.png" },
  { name: "balance_2", path: "/imgs/balance_2.png" },
  { name: "balance_3", path: "/imgs/balance_3.png" },
  { name: "btn_balance", path: "/imgs/btn_balance.png" },
  { name: "btn_balance1", path: "/imgs/btn_balance1.png" },
  { name: "btn_earn", path: "/imgs/btn_earn.png" },
  { name: "btn_earn1", path: "/imgs/btn_earn1.png" },
  { name: "btn_invite", path: "/imgs/btn_invite.png" },
  { name: "btn_invite1", path: "/imgs/btn_invite1.png" },
  { name: "btn_off", path: "/imgs/btn_off.png" },
  { name: "btn_reward", path: "/imgs/btn_reward.png" },
  { name: "btn_reward1", path: "/imgs/btn_reward1.png" },
  { name: "btn_top", path: "/imgs/btn_top.png" },
  { name: "btn_top1", path: "/imgs/btn_top1.png" },
  { name: "completed", path: "/imgs/completed.png" },
  { name: "earn", path: "/imgs/earn.png" },
  { name: "earn_1", path: "/imgs/earn_1.png" },
  { name: "hand-exm", path: "/imgs/hand/hand-exm.png" },
  { name: "point", path: "/imgs/hand/point.png" },
  { name: "invite_1", path: "/imgs/invite_1.png" },
  { name: "invite_2", path: "/imgs/invite_2.png" },
  { name: "maintenance", path: "/imgs/maintenance.png" },
  { name: "page1", path: "/imgs/page1.png" },
  { name: "page2", path: "/imgs/page2.png" },
  { name: "page3", path: "/imgs/page3.png" },
  { name: "page4", path: "/imgs/page4.png" },
  { name: "page5", path: "/imgs/page5.png" },
  { name: "page7", path: "/imgs/page7.png" },
  { name: "reward_1", path: "/imgs/reward_1.png" },
  { name: "reward_2", path: "/imgs/reward_2.png" },
  { name: "reward_3", path: "/imgs/reward_3.png" },
  { name: "reward_4", path: "/imgs/reward_4.png" },
  { name: "selected_white", path: "/imgs/selected_white.png" },
  { name: "share_facebook", path: "/imgs/share_facebook.png" },
  { name: "share_link", path: "/imgs/share_link.png" },
  { name: "share_twitter", path: "/imgs/share_twitter.png" },
  { name: "share_whatsapp", path: "/imgs/share_whatsapp.png" },
  { name: "share_x", path: "/imgs/share_x.png" },
  { name: "task tggroup", path: "/imgs/tasks/task%20tggroup.png" },
  { name: "task_boost", path: "/imgs/tasks/task_boost.png" },
  { name: "task_bot", path: "/imgs/tasks/task_bot.png" },
  { name: "task_folder", path: "/imgs/tasks/task_folder.png" },
  { name: "task_invite", path: "/imgs/tasks/task_invite.png" },
  { name: "task_phone", path: "/imgs/tasks/task_phone.png" },
  { name: "task_story", path: "/imgs/tasks/task_story.png" },
  { name: "task_tapppark", path: "/imgs/tasks/task_tapppark.png" },
  { name: "task_tgchannel", path: "/imgs/tasks/task_tgchannel.png" },
  { name: "task_ttchannel", path: "/imgs/tasks/task_ttchannel.png" },
  { name: "task_wallet", path: "/imgs/tasks/task_wallet.png" },
  { name: "ton", path: "/imgs/ton.png" },
  { name: "blance_1", path: "/imgs/banlance/blance_1.png" },
  { name: "blance_2", path: "/imgs/banlance/blance_2.png" },
  { name: "blance_3", path: "/imgs/banlance/blance_3.png" },
  { name: "blance_4", path: "/imgs/banlance/blance_4.png" },
  { name: "reward_rule", path: "/imgs/reward_rule.png" },
];

export const initArray: Array<Record<string, string>> = [
  { name: "page1", path: "/imgs/page1.png" },
  { name: "page2", path: "/imgs/page2.png" },
  { name: "page3", path: "/imgs/page3.png" },
  { name: "rank_1", path: "/imgs/rank/rank_1.png" },
  { name: "rank_2", path: "/imgs/rank/rank_2.png" },
  { name: "rank_3", path: "/imgs/rank/rank_3.png" },
  { name: "rank_settings", path: "/imgs/settings.png" },
  { name: "rank_search", path: "/imgs/search.png" },
  { name: "PalmCode", path: "/imgs/PalmCode.png" },
];
