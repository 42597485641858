import request from "./config";

export const getUserInfo = async () => {
  try {
    return await request.get("/api/user");
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const userLogin = async (inviter_tgid: string | number = "") => {
  try {
    const res = await request.post("/api/user/login", { inviter_tgid });
    if (res.code === 0) {
      return res.data;
    }
    return {};
  } catch (error) {
    console.log(error);
    return {};
  }
};

type PramsType = {
  palmistry_pic: string;
  reward: number;
  wallet?: string | number;
};
export const setUserPalmInfo = async (params: PramsType) => {
  try {
    const res = await request.post("/api/user/palmistry-analyze", params);
    if (res.code === 0) {
      return res.data;
    }
    return {};
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const putUserInfo = async (wallet: PramsType["wallet"]) => {
  try {
    const res = await request.put("/api/user", {
      wallet,
    });
    if (res.code === 0) {
      return res.data;
    }
    return {};
  } catch (error) {
    console.log(error);
    return {};
  }
};

type PramsDailyLoginRewardType = {
  is_watch_ad: boolean;
};
export const getDailyLoginReward = async (
  params: PramsDailyLoginRewardType
) => {
  try {
    const res = await request.post("/api/user/consecutive", params);
    if (res.code === 0) {
      return res.data;
    }
    return {};
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getDailyRewardInfo = async () => {
  try {
    const res = await request.get("/api/user/consecutive");
    if (res.code === 0) {
      return res.data;
    }
    return {};
  } catch (error) {
    console.log(error);
    return {};
  }
};
